import Post from "../Post";
import {useEffect, useState} from "react";

export default function IndexPage() {
  const [posts,setPosts] = useState([]);
  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = "https://www.maisondecorco.com";
  };	
  useEffect(() => {
    fetch('https://api.maisondecorco.com/post').then(response => {
      response.json().then(posts => {
        setPosts(posts);
      });
    });
  }, []);
  return (
    <>
      {posts.length > 0 && posts.map(post => (
        <Post {...post} />
      ))}
	<button className="double-left-arrow-button" onClick={handleClick}>
            <span className="double-left-arrow"></span>back to website
      	</button>  
    </>
  );
}
