


export default function Footer() {
  return (
    <footer class="footer">
        <p class="text-center-footer">Copyright © 2024 Maison Decor Company</p>
	<a href="https://www.freepik.com/free-photo/chic-mid-century-modern-luxury-aesthetics-living-room-with-gray-velvet-couch-golden-lamp_18665687.htm#page=2&query=black%20white%20living%20room&position=40&from_view=keyword&track=ais&uuid=1fc495c8-214d-4211-9764-d681f3d13387">Image by rawpixel.com</a> on Freepik  
    </footer>
  );
}
