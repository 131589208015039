// LoadingContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LoadingContext = createContext({ isLoading: true, setIsLoading: () => {} });

export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds
    const timeoutId = setTimeout(() => setIsLoading(false), 4000); // 2 seconds in milliseconds

    // Cleanup function to clear timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array to run only once on mount

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;

