import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loader = () => {
  return (
    <div className="loader-container">
      <FontAwesomeIcon icon={faSpinner} spin size="2x" />
    </div>
  );
};

export default Loader;
